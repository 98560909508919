<template>
  <div class="home">
    <v-app
      ><div v-if="!guest">
        <v-btn color="warning" class="black--text mr-4" @click="changeFlatform">
          {{ flatform }}
          <v-icon right dark>mdi-shoop</v-icon>
        </v-btn>
      </div>
      <v-div>
        <div class="text-center">
          <!-- <v-dialog v-model="progressBar" width="100" hide-overlay>
            <v-progress-circular
              :size="60"
              color="primary"
              indeterminate
              align-center
              justify-center
            >
            </v-progress-circular>
          </v-dialog> -->
          <v-dialog v-model="progressBar" persistent max-width="290" id="progressBar">
            <v-progress-linear indeterminate color="blue" height="30"
              ><span>Waits...</span></v-progress-linear
            >
          </v-dialog>
        </div>

        <br />

        <v-row>
          <v-col cols="12" md="8">
            <div>
              <v-item-group mandatory>
                <v-container>
                  <v-toolbar color="rgba(0,0,0,0)" flat>
                    <v-spacer></v-spacer>
                    <v-text-field
                      label="Search product..."
                      class="mt-5"
                      filled
                      append-icon="mdi-magnify"
                      dense
                      solo
                      flat
                      @click="search"
                      icon
                      rounded
                      color="#1b5e20"
                      outlined
                      elevation="5"
                      large
                    >
                    </v-text-field>
                  </v-toolbar>

                  <v-spacer></v-spacer>

                  <v-toolbar-title class="mt-n1 hidden-sm-and-down" v-if="!guest"
                    >Choose Category
                  </v-toolbar-title>
                  <br />
                  <v-row justify="center" class="space" v-if="!guest">
                    <v-slide-group v-model="model" class="pa-0" active-class="success">
                      <v-slide-item
                        v-for="category in categories"
                        :key="category"
                        v-slot="{ active, toggle }"
                      >
                        <v-card
                          :color="active ? '#F6EFEF' : 'white'"
                          :class="active ? 'borderme' : 'borderout'"
                          class="d-flex align-center rounded-lg mx-2"
                          dark
                          height="100"
                          width="100"
                          @click="selectCategory(category)"
                          flat
                        >
                          <v-img
                            :src="getImage('/' + category.image)"
                            class="red--text"
                            height="100"
                            width="100"
                          >
                          </v-img>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-row>
                  <br />
                  <v-toolbar flat v-if="products != 0">
                    <v-toolbar-title class="mt-n1 hidden-sm-and-down"
                      >Choose Product</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-container grid-list-sm v-if="products">
                    <v-layout wrap>
                      <v-flex
                        v-for="product in products"
                        :key="`product-` + product.id"
                        xs2
                      >
                        <product-item :product="product" height="100px" width="100px" />
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div>
                    <template>
                      <div class="text-center">
                        <v-pagination
                          v-model="page"
                          @input="selectCategory(category)"
                          :length="lengthPage"
                          :total-visible="5"
                          v-if="products != 0"
                          color="#1b5e20"
                        >
                        </v-pagination>
                      </div>
                    </template>
                  </div>
                </v-container>
              </v-item-group>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <SideBarRight />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div>
              <v-item-group mandatory class="mt-n1">
                <v-container>
                  <SideBarPromotion />
                </v-container>
              </v-item-group>
            </div>
          </v-col>
        </v-row>
      </v-div>
      <div class="text-center" v-if="!guest">
        <v-dialog v-model="platformMarket" width="500" overlay>
          <v-item-group selected-class="bg-primary">
            <v-container>
              <v-row>
                <v-col v-for="item in platform_market" :key="item" cols="12">
                  <v-item v-slot="{ isSelected, selectedClass, toggle }">
                    <v-card
                      :class="['d-flex align-center', selectedClass]"
                      color="green"
                      dark
                      height="100"
                      @click="selectFlatform(item.name)"
                    >
                      <div class="text-h3 flex-grow-1 text-center">
                        {{ isSelected ? "Selected" : item.name }}
                      </div>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-dialog>
      </div>
      <div v-if="guest">
        <v-dialog v-model="dialogVersion" max-width="400">
          <v-col class="text-right">
            <v-btn
              @click="resetHistory(), (dialogVersion = false)"
              icon
              rounded
              color="white"
              outlined
              elevation="5"
              large
              class="mx-4 warning"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-card class="mx-auto" color="warning" dark max-width="400">
            <v-card-title>
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                >
                </v-img>
              </v-list-item-avatar>
            </v-card-title>

            <v-card-text class="text-h5 font-weight-bold">
              "Mohon hapus cache / history penjelajahan browser anda untuk mendapatkan
              pembaruan aplikasi!"
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div v-if="guest">
        <v-container color="transparent">
          <v-dialog v-model="dialogHome" max-width="1000">
            <v-card color="transparent" :opacity="0">
              <v-col class="text-right">
                <v-btn
                  @click="dialogHome = false"
                  icon
                  rounded
                  color="white"
                  outlined
                  elevation="5"
                  large
                  class="mx-4 #1b5e20"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-tabs color="#1b5e20">
                <v-tab>Information</v-tab>
                <v-tab>Register</v-tab>
                <v-tab-item>
                  <v-card max-height="400" max-width="1000">
                    <v-container>
                      <v-carousel
                        cycle
                        height="300"
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item
                          v-for="(item, i) in list_images"
                          :key="i"
                          :src="getImage('/' + item.image_url)"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                        </v-carousel-item>
                      </v-carousel>
                    </v-container>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card max-height="400" max-width="1000">
                    <v-container fluid>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          v-model="name"
                          :rules="nameRules"
                          :counter="50"
                          label="Name"
                          required
                          append-icon="mdi-user"
                        ></v-text-field>
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                          append-icon="mdi-email"
                        >
                        </v-text-field>
                        <v-text-field
                          v-model="password"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="passwordRules"
                          :type="showPassword ? 'text' : 'password'"
                          label="Password"
                          hint="At least 6 characters"
                          counter
                          @click:append="showPassword = !showPassword"
                        >
                        </v-text-field>
                        <v-checkbox
                          v-model="checkbox"
                          :rules="[(v) => !!v || 'You must agree to continue!']"
                          label="Do You agree with our Privacy Policy?"
                          required
                        ></v-checkbox>
                        <div class="text-xs-center">
                          <v-btn color="#1b5e20" :disabled="!valid" @click="submit">
                            Register
                            <v-icon right dark>mdi-account-plus</v-icon>
                          </v-btn>
                          <v-btn @click="clear">
                            Reset
                            <v-icon right dark>mdi-lock-reset</v-icon>
                          </v-btn>
                        </div>
                      </v-form>
                    </v-container>
                  </v-card>
                </v-tab-item>
              </v-tabs>
              <v-footer color="transparent">
                <v-card class="flex" flat tile color="transparent">
                  <center>
                    <v-btn
                      v-for="icon in icons"
                      :key="icon"
                      class="mx-4 #1b5e20"
                      icon
                      rounded
                      color="white"
                      outlined
                      elevation="5"
                      large
                    >
                      <v-icon size="24px">
                        {{ icon }}
                      </v-icon>
                    </v-btn>
                  </center>
                </v-card>
              </v-footer>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import SideBarRight from "@/components/SideBarRight";
import SideBarPromotion from "@/components/SideBarPromotion";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapGetters({
      carts: "cart/carts",
      countCart: "cart/count",
      totalPrice: "cart/totalPrice",
      totalQuantity: "cart/totalQuantity",
      totalWeight: "cart/totalWeight",
      totalStock: "cart/totalStock",
      user: "auth/user",
      guest: "auth/guest",
      flatform: "flatform/flatform",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      addCart: "cart/add",
      removeCart: "cart/remove",
      setCart: "cart/set",
      setAuth: "auth/set",
      setFlatform: "flatform/set",
    }),
    resetHistory() {
      caches
        .keys()
        .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    },
    checkout() {
      this.close();
      this.$router.push({ path: "/checkout" });
    },
    close() {
      this.$emit("closed", false);
    },
    selectCategory(category) {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/categories/slug/" + category.slug;
      url = url + "?page=" + this.page;
      url = encodeURI(url);
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let category = data;
          this.category = category;
          this.products = category.products.data;
          this.page = category.products.current_page;
          this.lengthPage = category.products.last_page;
          if (this.products == 0) {
            this.setAlert({
              status: true,
              color: "warning",
              text: "Empty product, please select again in this category",
            });
            this.chategory();
          }
          this.progressBar = false;
        })
        .catch((error) => {
          this.progressBar = false;
          let { responses } = error;
          console.log(responses);
        });
    },
    search() {
      return this.$router.push({ path: "/search" });
    },
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/products-pos?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.products = data;
          // jumlah halaman di dapat dari meta endpoint products
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          this.progressBar = false;
          let { responses } = error;
          console.log(responses);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("name", this.name);
        formData.set("email", this.email);
        formData.set("password", this.password);
        this.axios
          .post("/register", formData)
          .then((response) => {
            let { data } = response.data;
            this.setAuth(data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Register success",
            });
            this.close();
          })
          .catch((error) => {
            let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          });

        this.progressBar = false;
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    getComboBox() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/purchase/platform-market-combobox", config)
        .then((response) => {
          let { data } = response.data;
          this.platform_market = data;
          this.progressBar = false;
        })
        .catch((error) => {
          this.progressBar = false;
          let { responses } = error;
          console.log(responses);
        });
    },
    selectFlatform(isSelected) {
      if (isSelected != null) {
        this.setFlatform(isSelected);
        this.platformMarket = false;
      }
    },
    changeFlatform() {
      this.setFlatform(null);
      this.platformMarket = true;
      this.setCart([]);
      this.getComboBox();
    },
    chategory() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/categories/random/100", config)
        .then((response) => {
          let { data } = response.data;
          this.categories = data;
          this.progressBar = false;
        })
        .catch((error) => {
          this.progressBar = false;
          let { responses } = error;
          console.log(responses);
        });
    },
  },
  data: () => ({
    progressBar: false,
    categories: [],
    list_images: [], //sourch image slider
    products: [],
    page: 0,
    lengthPage: 0,
    icons: [
      "mdi-facebook",
      "mdi-twitter",
      // 'mdi-linkedin',
      "mdi-instagram",
    ],
    dialogHome: false,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 50) || "Name must be less than 255 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) ||
        "E-mail must be valid",
    ],
    showPassword: false,
    password: "",
    passwordRules: [
      (v) => !!v || "Password required.",
      (v) => (v && v.length >= 6) || "Min 6 characters",
    ],
    checkbox: false,
    dialogVersion: false,
    version: "",
    platform_market: [],
    platformMarket: false,
  }),
  created() {
    this.progressBar = true;
    let config = {
      headers: {
        Authorization: "Bearer " + this.user.api_token,
      },
    };
    this.axios
      .get("/categories/random/100", config)
      .then((response) => {
        let { data } = response.data;
        this.categories = data;
        this.progressBar = false;
      })
      .catch((error) => {
        this.progressBar = false;
        let { responses } = error;
        console.log(responses);
      });

    this.axios
      .get("/commonImage/slider")
      .then((response) => {
        let { data } = response.data;
        this.list_images = data;
        this.progressBar = false;
      })
      .catch((error) => {
        let { responses } = error;
        console.log(responses);
        this.progressBar = false;
      });
    this.axios
      .get("/version")
      .then((response) => {
        let { data } = response.data;
        this.version = data;
        if (this.version == "1.0.5") {
          this.dialogHome = true;
        } else {
          this.dialogVersion = true;
        }
        this.progressBar = false;
      })
      .catch((error) => {
        this.progressBar = false;
        let { responses } = error;
        console.log(responses);
      });
    if (this.flatform == null) {
      this.platformMarket = true;
      this.getComboBox();
    }
  },
  components: {
    SideBarRight,
    SideBarPromotion,
    ProductItem: () => import("@/components/ProductItem.vue"),
  },
};
</script>
<style>
.v-card.borderme {
  border: 2px solid #704232 !important;
}

.v-card.borderout {
  border: 1px solid #bcaaa4 !important;
}
</style>
